/* ###### Chart  ###### */

.main-donut-chart {
  position: relative;
  border-radius: 50%;
  overflow: hidden;

  .slice {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .chart-center {
    position: absolute;
    border-radius: 50%;

    span {
      display: block;
      text-align: center;
    }
  }
}

.morris-wrapper-demo {
  height: 200px;
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
  text-align: center;
  margin: 0 auto;
}

@media (min-width: 576px) {
  .morris-wrapper-demo {
    height: 300px;
  }
}

.morris-donut-wrapper-demo {
  height: 200px;
}

@media (min-width: 576px) {
  .morris-donut-wrapper-demo {
    height: 250px;
  }
}

.chartjs-wrapper-demo {
  height: 250px;
}

@media (max-width: 330px) {
  .chartjs-wrapper-demo {
    width: 290px;
  }
}

@media (min-width: 992px) {
  .chartjs-wrapper-demo {
    height: 300px;
  }
}

.chartist-wrapper-demo {
  height: 200px;
}

@media (min-width: 768px) {
  .chartist-wrapper-demo {
    height: 300px;
  }
}

.morris-hover.morris-default-style {
  border-radius: 0;
}

.chart-donut {
  left: 0;
  position: relative;
}

#morrisDonut1 {
  height: 200px;
  width: 200px;

  svg {
    height: 200px;
    width: 200px;
    font-family: Roboto, sans-serif;
  }
}

#revenuechart {
  height: 273px !important;
  width:100% !important;
}


.flot-chart {
  width: 100%;
  height: 180px;
}

.flot-chart1 {
  width: 100%;
  height: 300px;
}

.flot-chart-wrapper {
  position: relative;
  margin-left: -16px;
  margin-right: -30px;
}

.chart-wrapper {
  position: absolute;
  right: -8px;
  bottom: 5px;
  left: -8px;
  position: relative;
  margin-left: -13px;
  margin-right: -13px;
}

#chartBar5 {
  height: 135px;
}

.echart-height {
  height: 250px;
}

.chart-height1 {
  min-height: 15rem;
  overflow: hidden;
}

.chart-height2 {
  height: 16rem;
  overflow: hidden;
}

.chart-height3 {
  height: 17rem;
  overflow: hidden;
}

.chart-height4 {
  height: 18rem;
  overflow: hidden;
}

.chart-height5 {
  height: 20rem;
  overflow: hidden;
}

.chart-height6 {
  height: 22rem;
  overflow: hidden;
}

.chart-text {
  text-align: center;
  margin: 0 auto;
}

.areaChart1, .areaChart2, .areaChart3, .areaChart4 {
  display: block;
  position: relative;
  width: 80px !important;
  height: 50px !important;
}

.chart-legEnd {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  z-index: 1;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;

  div {
    display: flex;
    align-items: center;

    + div {
      margin-left: 15px;
    }
  }

  span {
    width: 10px;
    height: 4px;
    display: block;
    margin-right: 5px;
  }
}

@media (min-width: 576px) {
  .chart-legEnd {
    span {
      width: 30px;
      margin-right: 8px;
    }

    div + div {
      margin-left: 30px;
    }

    top: -30px;
    left: auto;
    right: 40px;
  }
}

#bar-chart {
  height: 75px !important;
  width:100% !important;
}

.chart-dropshadow2 {
  -webkit-filter: drop-shadow((-6px) 12px 4px rgba(0, 0, 0, 0.1));
  filter: drop-shadow((-6px) 12px 4px rgba(0, 0, 0, 0.1));
}

#chart svg path#apexcharts-radialbarTrack-0 {
  stroke: transparent;
}

/* ###### Chart  ###### */

/* ###### Mapel map  ###### */

.mapael {
  .map {
    margin-bottom: 10px;
    position: relative;
  }

  .zoomButton {
    background-color: #292929;
    border: 1px solid #292929;
    color: $white;
    width: 15px;
    height: 15px;
    line-height: 12px;
    text-align: center;
    border-radius: 3px;
    cursor: pointer;
    position: absolute;
    top: 0;
    font-weight: bold;
    left: 10px;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

  .zoomReset {
    top: 10px;
  }

  .zoomIn {
    top: 30px;
  }

  .zoomOut {
    top: 50px;
  }

  .mapTooltip {
    position: absolute;
    background-color: #474c4b;
    opacity: 0.70;
    filter: alpha(opacity = 70);
    border-radius: 10px;
    padding: 10px;
    z-index: 1000;
    max-width: 200px;
    display: none;
    color: $white;
  }
}

.mapcontainer .map, .mapcontainer1 .map {
  height: 400px;
  width: 400px;
  margin: 0 auto;
}

.mapcontainer2 .map {
  height: 300px;
  width: 300px;
  margin: 0 auto;
}

.mapcontainer3 svg {
  height: 300px;
  width: 100%;
}
/* ###### Mapel map  ###### */
